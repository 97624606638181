import React from 'react';
import { SvgIcon } from '@mui/material';

const LearningGoalsIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path d="M11.8622 4.79534L12.4031 6.39387L9.44606 9.30756C9.13988 9.60925 9.13988 10.0975 9.44606 10.3992C9.75192 10.7006 10.2478 10.7009 10.5539 10.3992L13.511 7.4855L15.1333 8.01844C15.3745 8.09757 15.6402 8.03581 15.8201 7.85891L17.8984 5.81107C18.2509 5.4637 18.1093 4.87189 17.636 4.71622L15.8191 4.11959L15.2136 2.32969C15.0559 1.86332 14.4553 1.72373 14.1025 2.0711L12.0245 4.11862C11.9364 4.2055 11.8746 4.3148 11.8459 4.43431C11.8173 4.55381 11.8229 4.67882 11.8622 4.79534ZM10 6.50837C10.25 6.50837 10.4922 6.53925 10.7269 6.59007L11.2081 6.11598L10.8712 5.12052C10.8634 5.09704 10.8647 5.07324 10.8578 5.04944C10.5781 5.00119 10.2938 4.9642 9.99968 4.9642C7.25709 4.9642 5.03808 7.15132 5.03808 9.85305C5.03808 12.5554 7.25774 14.7419 9.99968 14.7419C12.7423 14.7419 14.9613 12.5548 14.9613 9.85305C14.9613 9.56261 14.9237 9.28215 14.8748 9.00586C14.8513 8.99911 14.8261 9.002 14.803 8.99428L13.793 8.66268L13.3119 9.13677C13.3634 9.36802 13.3945 9.60668 13.3945 9.85305C13.3945 11.6976 11.8717 13.198 9.99968 13.198C8.12765 13.198 6.6049 11.6976 6.6049 9.85305C6.6049 8.00847 8.12798 6.50837 10 6.50837ZM17.7176 7.44433L16.5588 8.58613C16.515 8.62923 16.4651 8.66268 16.4178 8.70031C16.487 9.07501 16.5284 9.45905 16.5284 9.85305C16.5284 13.4 13.5998 16.2857 10 16.2857C6.40023 16.2857 3.47158 13.4 3.47158 9.85305C3.47158 6.30606 6.40023 3.42036 10 3.42036C10.4028 3.42036 10.7955 3.46153 11.1784 3.53068C11.2162 3.485 11.243 3.4329 11.2861 3.39044L12.4449 2.24864C11.6541 2.00209 10.8295 1.87669 10 1.87683C5.52901 1.87683 1.90476 5.44794 1.90476 9.85337C1.90476 14.2588 5.52901 17.8299 10 17.8299C14.471 17.8299 18.0952 14.2588 18.0952 9.85337C18.0952 9.01358 17.9617 8.20467 17.7176 7.44433Z" fill={props?.pathfill}/>
  </SvgIcon>
);

export default LearningGoalsIcon;
