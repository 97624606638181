import React from 'react';
import { SvgIcon } from '@mui/material';

const DistrictComparisonIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V9C0 10.6569 1.34315 12 3 12H13C14.6569 12 16 10.6569 16 9V3C16 1.34315 14.6569 0 13 0H3ZM2 9V8H7V10H3C2.44772 10 2 9.55228 2 9ZM9 10H13C13.5523 10 14 9.55229 14 9V8H9V10ZM9 6H14V4H9V6ZM7 4H2V6H7V4Z" fill={props?.pathfill}/>
  </SvgIcon>
);

export default DistrictComparisonIcon;
