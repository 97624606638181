/* eslint-disable indent */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import HelpModal from './HelpModal';
import { StyledNextButton } from './SubmitTask.styled';
import ConfirmationImage from '../../../components/Icons/ConfirmationImage';
import SuccessImage from '../../../components/Icons/SuccessImage';
import { SubmissionStatus } from '../../../generated/graphql';
import { SubmitTaskContext } from '../submit-task-context';
import { ButtonVariant, ButtonType } from '../../../variables/types';

interface Props {
  status: SubmissionStatus,
  showConfirmationDialog?: boolean,
  handleNext?: Function,
  disabled?: boolean,
  nextBtnLabel?: string,
}


const NextButton = ({
  status,
  handleNext,
  showConfirmationDialog = true,
  disabled = false,
  nextBtnLabel = 'Next',
}: Props) => {
  const {
    showSuccess,
    showConfirmation,
    setShowConfirmation,
  } = useContext(SubmitTaskContext);
  // const classes = useStyles();
  const history = useHistory();

  const handleNextConfirmation = () => {
    if (showConfirmationDialog) {
      setShowConfirmation?.(true);
    } else {
      handleNext?.();
    }
  };

  const getHelpDialogData = () => {
    const dialogTitle = 'Are you sure you want to continue?';
    const dialogImage = <ConfirmationImage sx={{  height: 300, width: '100%' }} />;
    let dialogContent;
    let dialogAudio;
    switch (status) {
      case SubmissionStatus.Started:
        dialogContent = 'There are no labels on your picture.';
        dialogAudio = '/audio/NoLabels.mp3';
        break;
      case SubmissionStatus.AnnotationCompleted:
        dialogContent = 'There are no recordings.';
        dialogAudio = '/audio/NoRecordings.mp3';
        break;
      case SubmissionStatus.SpeakingCompleted:
        dialogContent = 'There is no written content.';
        dialogAudio = '/audio/NoWrittenContent.mp3';
        break;
      default:
        dialogContent = 'There are no labels on your picture.';
        dialogAudio = '/audio/NoLabels.mp3';
    }
    return {
      dialogContent,
      dialogImage,
      dialogTitle,
      dialogAudio,
    };
  };

  const { dialogTitle, dialogContent, dialogImage, dialogAudio } = getHelpDialogData();
  return (
    <>
      <StyledNextButton
        variant="contained"
        color="primary"
        onClick={handleNextConfirmation}
        disabled={disabled}
      >
        {nextBtnLabel ?? 'Next'}
      </StyledNextButton>
      <HelpModal
        openDialog={showConfirmation}
        closeDialog={() => setShowConfirmation?.(false)}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogImage={dialogImage}
        dialogAudioText={dialogContent}
        dialogAudio={dialogAudio}
        actions={[...(status === SubmissionStatus.Started ? [{
          title: 'Yes',
          event: () => {
            setShowConfirmation?.(false);
            handleNext?.();
          },
          buttonVariant: 'outlined' as ButtonVariant,
          btnType: 'danger' as ButtonType,
        }] : []), {
          title: 'Go Back',
          event: () => setShowConfirmation?.(false),
          buttonVariant: 'contained',
          buttonColor: 'primary',
          btnType: 'default',
        }]}
      />
      <HelpModal
        openDialog={showSuccess}
        dialogTitle="You’re all done!"
        dialogContent="Your teacher will meet with you soon."
        dialogImage={<SuccessImage sx={{  height: 300, width: '100%' }} />}
        isSuccessModal
        dialogAudioText="Your teacher will meet with you soon."
        dialogAudio="/audio/SuccessPopUp.mp3"
        actions={[{
          title: 'Go Home',
          event: () => history.push('/'),
          buttonVariant: 'contained',
          buttonColor: 'primary',
          btnType: 'default',
        }]}
      />
    </>
  );
};

export default React.memo(NextButton);
